import React from 'react';
import classnames from 'classnames';
import {
  NavbarBrand,
  Navbar,
  Container,
} from 'reactstrap';

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState('navbar-transparent');

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor('');
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor('navbar-transparent');
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames('fixed-top', navbarColor)} expand='lg'>
      <Container>
        <div className='navbar-translate'>
          <NavbarBrand data-placement='bottom' href='#'>
            Phillip Walter
          </NavbarBrand>
          <NavbarBrand href="https://gitlab.com/FreakyGreen119" target="_blank" title="Gitlab">
          <i className='fa fa-gitlab'/>
          </NavbarBrand>
          <NavbarBrand href="https://overview.phillipwalter.dev/" target="_blank" title="Projects">
          <i className='fa fa-draw-polygon'/> Projects
          </NavbarBrand>
        </div>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
