import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import 'assets/css/bootstrap.min.css';
import 'assets/scss/paper-kit.scss';
import 'assets/css/main.css';
import Index from 'views/Index.js';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter basename={window.location.pathname || ''}>
      <Routes>
        <Route path='/' element={<Index />}/>
      </Routes>
    </BrowserRouter>
);
