import "../assets/css/skills.css"
import React from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

function Skills() {
    var iconPadding = {padding: "10px"};

    return (
    <>
        <Container>
            <h2 className="text-center">Skills</h2>
            <Row>
                <Col md='6'>
                    <h2 className='text-center'>Code</h2>
                    <Row>
                        <Col md='12'>
                            <h1 className='text-center'>
                                <Col>
                                    <i className="fa-brands fa-html5 resizer" style={iconPadding} title="HTML"/>
                                    <i className="fa-brands fa-css3 resizer" style={iconPadding} title="CSS"/>
                                    <i className="fa-brands fa-react resizer" style={iconPadding} title="React JS"/>
                                    <i className="fa-brands fa-square-js resizer" style={iconPadding} title="JavaScript"/>
                                    <i className="fa-brands fa-java resizer" style={iconPadding} title="Java"/>
                                    <i className="fa-brands fa-python resizer" style={iconPadding} title="Python"/>
                                </Col>
                                <Col>
                                    <i className="fa-brands fa-linux resizer" style={iconPadding} title="Linux"/>
                                    <i className="fa-brands fa-node resizer" style={iconPadding} title="Node JS"/>
                                    <i className="fa-brands fa-aws resizer" style={iconPadding} title="AWS"/>
                                    <i className="fa-brands fa-docker resizer" style={iconPadding} title="Docker"/>
                                    <i className="fa-brands fa-git-alt resizer" style={iconPadding} title="Git"/>
                                </Col>
                            </h1>
                        </Col>
                    </Row>
                </Col>
                <br />
                <Col md='6'>
                    <h2 className='text-center'>Languages</h2>
                    <Row>
                        <Col md='12'>
                            <Col>
                                <h3 className='text-center'>German</h3>
                                <h3 className='text-center'>English</h3>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
    );
}

export default Skills;