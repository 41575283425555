import "../assets/css/cards.css"
import React from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

function Certificates() {    
    var iconPadding = {padding: "10px"};

    return (
    <>
        <Container>
            <Row>
                <Col md='12'>
                    <h2 className='text-center'>Certificates</h2>
                    <section className="card-section">
                        <div className="card-grid">
                            <a className="card">
                                <div className="card__background" style={{backgroundImage: `url("https://images.unsplash.com/photo-1639066648921-82d4500abf1a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80")`}} ></div>
                                <div className="card__content">
                                    <p className="card__category">AWS</p>
                                    <h3 className="card__heading">Certified Cloud Practitioner</h3>
                                    <p className="card__paragraph">2022</p>
                                </div>
                            </a>
                            <a className="card">
                                <div className="card__background" style={{backgroundImage: `url("https://images.unsplash.com/photo-1615829386703-e2bb66a7cb7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80")`}}></div>
                                <div className="card__content">
                                    <p className="card__category">MAN Truck & Bus SE</p>
                                    <h3 className="card__heading">High Voltage</h3>
                                    <p className="card__paragraph">Level 1E <br/> DGUV I 209-093</p>
                                    <p className="card__paragraph">2022</p>
                                </div>
                            </a>
                            <a className="card">
                                <div className="card__background" style={{backgroundImage: `url("https://images.unsplash.com/photo-1594904351111-a072f80b1a71?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80")`}}></div>
                                <div className="card__content">
                                    <p className="card__category">Udacity</p>
                                    <h3 className="card__heading">Fullstack Web Developer</h3>
                                    <p className="card__paragraph">2020<br/>Nanodegree</p>
                                </div>
                            </a>
                            <a className="card">
                            <div className="card__background" style={{backgroundImage: `url("https://images.unsplash.com/photo-1603989872628-7880d83bb581?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8ZW5nbGlzaHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60")`}}></div>
                                <div className="card__content">
                                    <p className="card__category">Language Training</p>
                                    <h3 className="card__heading">English</h3>
                                    <p className="card__paragraph">2019 <br/>Kern Training B2/C1</p>
                                    <p className="card__paragraph">2014 <br/>Cambridge B1</p>
                                </div>
                            </a>
                        </div>
                    </section>
                </Col>
            </Row>
        </Container>
    </>
    );
}

export default Certificates;