import React from 'react';
import { Row, Container } from 'reactstrap';

function Footer() {
  return (
    <footer className='footer footer-black footer-white'>
      <Container>
        <Row>
          <div className='credits ml-auto text-center' style={{ margin: '0 auto' }}>
            <span className='copyright '><br/>
              © {new Date().getFullYear()}, made with{' '}
              <i className='fa fa-heart heart' /> by Phillip
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
