import React from 'react';
import { Container } from 'reactstrap';
import Typical from 'react-typical';
import '../../assets/css/Scroll-animation.css';

function Header() {
  return (
    <>
      <div
        className='page-header section-dark'
        style={{
          backgroundImage:
            'linear-gradient(to top, rgba(0,0,0,0.6) 15%, rgba(0,0,0,0) 100%), url("https://images.unsplash.com/photo-1522252234503-e356532cafd5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1450&q=80")',
        }}
      >
        <div className='filter' />
        <div className='content-center'>
          <Container>
            <div className='title-brand'>
              <h1 className='presentation-title'>
                Servus, I am
                <br />
                Phillip <i className="fa-solid fa-hand-peace heart"></i>
              </h1>
            </div>
            <Typical
              className='presentation-subtitle text-center'
              steps={[
                'Front End Software Engineer',
                1500,
                'Car Enthusiast',
                1500,
                'Motorcyclist',
                1500,
              ]}
              loop={Infinity}
              wrapper='h1'

            />
          </Container>
        </div>
      </div>
      <div className='mouse' style={{ zIndex: '100' }} />
    </>
  );
}

export default Header;
