import React from 'react';

import Header from 'components/Headers/Header.js';
import Navbar from 'components/Headers/Navbar.js';
import Footer from 'components/Footers/Footer.js';

import Paragraph from '../components/Paragraph';
import Experience from 'components/Experience';
import Skills from 'components/Skills';
import Certificates from 'components/Certificates';
import LinksSection from '../components/LinksSection';

function Index() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('index');
    return function cleanup() {
      document.body.classList.remove('index');
    };
  });
  return (
    <>
      <Navbar />
      <Header />
      <div className='main'>
        <Paragraph />
        <br/>
        <Experience/>
        <br/>
        <Skills/>
        <br/>
        <Certificates/>
        <br/>
        <LinksSection />
        <Footer />
      </div>
    </>
  );
}

export default Index;
