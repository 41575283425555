import "../assets/css/skills.css"
import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

class LinksSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { age: 0 };
  }

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col className="text-center" md='4'>
              <div className='info'>
                <div className='icon'>
                  <i className='fa fa-draw-polygon resizer'/>
                </div>
                <div className='description'>
                  <h4 className='info-title'>Project Overview</h4>
                  <p className='description'>
                    Coming Soon. 
                  </p>
                  <Button
                    className='btn-link'
                    color='info'
                    //href='https://overview.phillipwalter.dev'
                    rel='noopener noreferrer'
                  >
                    <i className='fa fa-code' />{" "} Project Overview
                  </Button>
                </div>
              </div>
            </Col>
            <Col className="text-center" md='4'>
              <div className='info'>
                <div className='icon'>
                  <i className='fa-brands fa-linkedin resizer'/>
                </div>
                <div className='description'>
                  <h4 className='info-title'>LinkedIn</h4>
                  <p className='description'>
                    Find me on LinkedIn.
                  </p>
                  <br/>
                  <Button
                    className='btn-link'
                    color='info'
                    href='https://de.linkedin.com/in/phillip-walter'
                    rel='noopener noreferrer'
                  >
                    <i className='fa-brands fa-linkedin'/>{" "} LinkedIn
                  </Button>
                </div>
              </div>
            </Col>
            <Col className="text-center" md='4'>
              <div className='info'>
                <div className='icon'>
                  <i className='fa-brands fa-gitlab resizer'/>
                </div>
                <div className='description'>
                  <h4 className='info-title'>GitLab</h4>
                  <p className='description'>
                    Checkout my GitLab.
                  </p>
                  <br/>
                  <Button
                    className='btn-link'
                    color='info'
                    href='https://gitlab.com/FreakyGreen119'
                    rel='noopener noreferrer'
                  >
                    <i className='fa-brands fa-gitlab'/>{" "} GitLab
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default LinksSection;
